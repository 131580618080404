import { connect } from 'react-redux'
import { saveBallot } from '@ballotready/redux-api-client'

const mapStateToProps = ({ appConfig: { supportedLocales } }) => ({
  locales: supportedLocales,
})

const mapDispatchToProps = dispatch => ({
  onLocaleChange: newLocale => dispatch(saveBallot({ locale: newLocale })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
