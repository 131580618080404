import React from 'react'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { Button } from '@ballotready/sparkles'
import IneligibleModal from './IneligibleModal/IneligibleModal'
import PlaceField from 'components/sparkles/PlaceField/PlaceField'
import addressFormContainer, { StateProps } from './addressFormContainer'
import './AddressForm.scss'
import Checkbox from './Checkbox/Checkbox'
import LocaleSwitcher from 'components/sparkles/LocaleSwitcher/LocaleSwitcher'
import { RouteComponentProps } from '@reach/router'
import { AddressFormValues, OptionType } from 'types'
import useAnalytics from 'hooks/useAnalytics'
import { AnalyticsContextType } from 'contexts/AnalyticsContext/AnalyticsContext'
import SelectField from 'components/sparkles/SelectField/SelectField'
import { components, MenuProps } from 'react-select'
import MapBox from 'components/maptv/MapBox/MapBox'
import { ButtonVariantType, ButtonSizeType } from '@ballotready/sparkles'

export interface OwnProps {
  buttonOutline?: boolean
  buttonText?: string
  buttonVariant?: ButtonVariantType
  fieldOptions?: QueryParams
  ineligible?: boolean
  onSubmit?(
    values: AddressFormValues,
    history?: any,
    analytics?: AnalyticsContextType,
  ): void
  redirect?: boolean
  size?: ButtonSizeType
  showTip?: boolean
  showLabels?: boolean
  pledgeFrom?: boolean
}

export interface QueryParams {
  input_custom_field?: string
  input_email?: string
  input_first_name?: string
  input_last_name?: string
  input_locale?: string
  input_phone?: string
  input_notifications_opt_in?: string
  theme?: string
}

type Props = OwnProps & StateProps & RouteComponentProps<QueryParams>

// Make widget grow when dropdown is opened
const Menu: React.FC<MenuProps<OptionType>> = props => (
  <components.Menu {...props}>
    <>
      {props.children}
      <span data-iframe-height></span>
    </>
  </components.Menu>
)

export const AddressForm: React.FC<Props> = ({
  appConfig,
  ballot,
  buttonOutline,
  buttonText,
  buttonVariant = 'danger',
  fieldOptions,
  ineligible,
  locales,
  onSubmit,
  sampleBallotAddress,
  size = 'lg',
  showTip = true,
  showLabels,
  widget: { showMap, mapCenter },
}: Props) => {
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const analytics = useAnalytics()

  // Extracts widget options from query string
  let {
    input_custom_field,
    input_email,
    input_first_name,
    input_last_name,
    input_locale,
    input_phone,
    input_notifications_opt_in,
    theme,
  } = qs.parse(location.search, { ignoreQueryPrefix: true })

  if (fieldOptions) {
    input_custom_field = fieldOptions.input_custom_field
    input_email = fieldOptions.input_email
    input_first_name = fieldOptions.input_first_name
    input_last_name = fieldOptions.input_last_name
    input_locale = fieldOptions.input_locale
    input_phone = fieldOptions.input_phone
    input_notifications_opt_in = fieldOptions.input_notifications_opt_in
    theme = fieldOptions.theme
  }

  const colorTheme = theme === 'dark' ? 'dark' : 'light'

  // we probably want to add some more sophisticated logic here around when we want to show the ineligible modal
  // current logic keeps it from popping up in standalone tools that require a ballot but do not have an election
  // (ex. voter registration)
  const shouldShowIneligibleModal = appConfig.enableElectionCenter

  return (
    <Formik
      initialValues={{
        address: ballot && ballot.address ? ballot.address : '',
        first_name: ballot && ballot.first_name ? ballot.first_name : '',
        last_name: ballot && ballot.last_name ? ballot.last_name : '',
        locale: ballot && ballot.locale ? ballot.locale : locales[0],
        phone: ballot && ballot.phone ? ballot.phone : '',
        email: ballot && ballot.email ? ballot.email : '',
        notifications_opt_in: !!(ballot && ballot.notifications_opt_in),
      }}
      onSubmit={values => {
        analytics?.track('Address Form Submitted', values)

        const fbq = (window as any)?.fbq
        if (fbq) {
          fbq('track', 'CompleteRegistration')
          fbq('trackCustom', 'ballotAction', { name: 'entered_address' })
        }

        onSubmit && onSubmit(values, history, analytics)
      }}
    >
      {({ handleSubmit, isSubmitting, resetForm, setFieldValue }) => {
        const handleLocaleChange = (locale: string) => {
          i18n
            .changeLanguage(locale)
            .then(() => setFieldValue('locale', locale))
            .catch(err =>
              console.error('An error occurred while switching locales', err),
            )
        }

        const handleDropPin = (latLng: google.maps.LatLng) => {
          setFieldValue('address', `${latLng.lat()}, ${latLng.lng()}`)
        }

        return (
          <form
            className={classNames('AddressForm', {
              [`AddressForm--${size}`]: size,
            })}
            data-iframe-height
            data-testid="address-form"
            onSubmit={handleSubmit}
          >
            {shouldShowIneligibleModal && ineligible && (
              <IneligibleModal onClose={resetForm} />
            )}
            {input_first_name && (
              <div className="AddressForm__formGroup">
                {showLabels && (
                  <label
                    className={classNames(
                      'AddressForm__label',
                      `AddressForm__label--${colorTheme}`,
                    )}
                    htmlFor="first_name"
                  >
                    {t('AddressForm.firstName')}
                    {input_first_name === 'required' ? '*' : ''}
                  </label>
                )}
                <Field
                  className="AddressForm__input AddressForm__FirstName"
                  name="first_name"
                  placeholder={t('AddressForm.firstNamePlaceholder')}
                  required={input_first_name === 'required'}
                />
              </div>
            )}
            {input_last_name && (
              <div className="AddressForm__formGroup">
                {showLabels && (
                  <label
                    className={classNames(
                      'AddressForm__label',
                      `AddressForm__label--${colorTheme}`,
                    )}
                    htmlFor="last_name"
                  >
                    {t('AddressForm.lastName')}
                    {input_last_name === 'required' ? '*' : ''}
                  </label>
                )}
                <Field
                  className="AddressForm__input AddressForm__LastName"
                  name="last_name"
                  placeholder={t('AddressForm.lastNamePlaceholder')}
                  required={input_last_name === 'required'}
                />
              </div>
            )}
            {input_email && (
              <div className="AddressForm__formGroup AddressForm__email">
                {showLabels && (
                  <label
                    className={classNames(
                      'AddressForm__label',
                      `AddressForm__label--${colorTheme}`,
                    )}
                    htmlFor="email"
                  >
                    {t('AddressForm.email')}
                    {input_email === 'required' ? '*' : ''}
                  </label>
                )}
                <Field
                  className="AddressForm__input"
                  name="email"
                  placeholder={t('AddressForm.emailPlaceholder')}
                  required={input_email === 'required'}
                  type="email"
                />
              </div>
            )}

            {input_phone && (
              <div className="AddressForm__formGroup">
                {showLabels && (
                  <label
                    className={classNames(
                      'AddressForm__label',
                      `AddressForm__label--${colorTheme}`,
                    )}
                    htmlFor="phone"
                  >
                    {t('AddressForm.phone')}
                    {input_phone === 'required' ? '*' : ''}
                  </label>
                )}
                <Field
                  className="AddressForm__input AddressForm__Phone"
                  name="phone"
                  placeholder={t('AddressForm.phonePlaceholder')}
                  required={input_phone === 'required'}
                  type="phone"
                />
              </div>
            )}
            {input_custom_field && (
              <div className="AddressForm__formGroup">
                {showLabels && (
                  <label
                    className={classNames(
                      'AddressForm__label',
                      `AddressForm__label--${colorTheme}`,
                    )}
                    htmlFor="custom"
                  >
                    {t('AddressForm.customField')}
                    {input_phone === 'required' ? '*' : ''}
                  </label>
                )}
                <Field
                  component={SelectField}
                  components={{ Menu }}
                  menuPlacement="top"
                  name="custom"
                  options={appConfig.widget.customField.options}
                  placeholder={t('AddressForm.customFieldPlaceholder')}
                  required={input_custom_field === 'required'}
                />
              </div>
            )}
            <div className="AddressForm__formGroup AddressForm__address">
              {showLabels && (
                <label
                  className={classNames(
                    'AddressForm__label',
                    `AddressForm__label--${colorTheme}`,
                  )}
                  htmlFor="address"
                >
                  {t('AddressForm.address')}
                </label>
              )}
              <Field
                component={PlaceField}
                hint={
                  showTip && showMap
                    ? t('AddressForm.tipMap')
                    : t('AddressForm.tip')
                }
                inputClassName="AddressForm__input"
                name="address"
                placeholder={t('AddressForm.addressPlaceholder', {
                  address: sampleBallotAddress,
                })}
                required
              />
            </div>
            {showMap && (
              <MapBox
                canDropPin
                className="AddressForm__map"
                onDropPin={handleDropPin}
                center={mapCenter}
              />
            )}

            {input_notifications_opt_in && (
              <Field
                component={Checkbox}
                name="notifications_opt_in"
                key={1}
                id="notifications_opt_in"
                className={classNames('AddressForm__checkbox', {
                  'AddressForm__label--dark': colorTheme === 'dark',
                  'AddressForm__label--light': colorTheme === 'light',
                })}
              >
                {t('AddressForm.optIn')}
              </Field>
            )}

            {input_locale && locales.length > 1 && (
              <div className="AddressForm__formGroup AddressForm__locale">
                {showLabels && (
                  <label
                    className={classNames(
                      'AddressForm__label',
                      `AddressForm__label--${colorTheme}`,
                    )}
                    htmlFor="address"
                  >
                    {t('AddressForm.locale')}
                  </label>
                )}
                <LocaleSwitcher
                  currentLocale={i18n.language}
                  locales={locales}
                  onChange={handleLocaleChange}
                />
              </div>
            )}

            <div>
              <Button
                busy={isSubmitting}
                className={classNames(
                  'AddressForm__submit',
                  showLabels && 'AddressForm__submit-margin',
                )}
                outline={buttonOutline}
                size={size}
                square
                type="submit"
                variant={buttonVariant}
              >
                {buttonText || t('AddressForm.submit')}
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default addressFormContainer(AddressForm)
