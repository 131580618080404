import React from 'react'
import PropTypes from 'prop-types'

import GoogleG from './google-g.svg'
import './GoogleLoginButton.scss'

class GoogleLoginButton extends React.Component {
  static propTypes = {
    onLoginFailed: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.btnRef = React.createRef()
    this.gapi = window.gapi

    // Do not enable Google login if Google SDK not loaded
    this.enabled = props.clientId && this.gapi && this.gapi.load
  }

  componentDidMount() {
    const { clientId, onLoginSuccess, onLoginFailed } = this.props

    if (this.enabled) {
      this.gapi.load('auth2', () => {
        const auth2 = this.gapi.auth2.init({
          client_id: clientId,
          cookiepolicy: 'single_host_origin',
        })

        auth2.attachClickHandler(
          this.btnRef.current,
          {},
          googleUser =>
            onLoginSuccess({
              auth_id: googleUser.getId(),
              email: googleUser.getBasicProfile().getEmail(),
              social_token: googleUser.getAuthResponse().id_token,
            }),
          error => onLoginFailed(error),
        )
      })
    }
  }

  render() {
    if (!this.enabled) {
      return null
    }

    // Weird __Content div because of Chrome/Webkit bug: https://bugs.chromium.org/p/chromium/issues/detail?id=375693
    return (
      <button className="GoogleLoginButton" ref={this.btnRef}>
        <div className="GoogleLoginButton__Content">
          <div className="GoogleLoginButton__icon">
            <img alt="Google Logo" src={GoogleG} />
          </div>
          <div className="GoogleLoginButton__inner">{this.props.children}</div>
        </div>
      </button>
    )
  }
}

GoogleLoginButton.propTypes = {
  clientId: PropTypes.string.isRequired,
  onLoginFailed: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
}

export default GoogleLoginButton
