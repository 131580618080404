import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { Markdown } from '@ballotready/sparkles'
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher'
import footerContainer from './footerContainer'
import './Footer.scss'

const Footer = ({
  disclaimer,
  hasBallotSidebar,
  locales,
  onLocaleChange,
  supportPortalUrl,
}) => {
  const { i18n, t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const handleLocaleChange = locale => {
    i18n
      .changeLanguage(locale)
      .then(() => onLocaleChange(locale))
      .catch(err =>
        console.error('An error occurred while switching locales', err),
      )
  }

  return (
    <footer
      className={classNames('Footer', {
        'Footer--with-sidebar': hasBallotSidebar,
      })}
    >
      <div className="Footer__container">
        {disclaimer && (
          <Markdown className="Footer__disclaimer" source={disclaimer} />
        )}
        <p>
          <Trans i18nKey="sparkles:Footer.supportPortal" parent="strong">
            Need help?
            <a
              href={supportPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
            .
          </Trans>
        </p>
        <Trans i18nKey="sparkles:Footer.rights" parent="p">
          Powered by
          <a
            href="https://civicengine.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            CivicEngine
          </a>
          . Copyright © {{ currentYear }}
          <a
            href="https://civicengine.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            CivicEngine
          </a>
          . All Rights Reserved.
        </Trans>

        <ul className="Footer__Links">
          <li>
            <a
              href="https://www.civicengine.com/terms-of-use"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('sparkles:Footer.termsOfUse')}
            </a>
          </li>
          <li>
            <a
              href="https://www.civicengine.com/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('sparkles:Footer.privacyPolicy')}
            </a>
          </li>
        </ul>

        {locales && locales.length > 1 && (
          <div className="Footer__Locale">
            <FontAwesomeIcon icon={faGlobe} />
            Language:
            <LocaleSwitcher
              className="Footer__LocaleSwitcher"
              currentLocale={i18n.language}
              locales={locales}
              onChange={handleLocaleChange}
            />
          </div>
        )}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  disclaimer: PropTypes.string,
  hasBallotSidebar: PropTypes.bool,
  supportPortalUrl: PropTypes.string,
}

Footer.defaultProps = {
  hasBallotSidebar: false,
}

export default footerContainer(Footer)
